export const listTemplate = {
    data:() => ({
        id: null,
        datas: [],
        filters: {
            search: '',
        },
        pagination: {
            page: 1,
            pageSize: 20,
        },
        total:0,
        modalVisible:false,
        modalTitle:'',
        firstTime:true,
    }),
    computed: {
        params() {
            return { ...this.pagination, ...this.filters }
        },
    },
    mounted() {
        this.fetchList();
    },
    methods: {
        fetchList() {

            this.modalVisible = false;
            this.$store.dispatch('settings/setLoading',true);
            this.actions.get(this.params).then(({data}) => {
                if(this.filters.export) {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.xlsx');
                    document.body.appendChild(link);
                    link.click()
                }
                if(data.data) {
                    if(data.data.data) {
                        this.datas = data.data.data;
                    }
                    else {
                        this.datas = data.data;
                    }
                    if(data.data.total) {
                        this.total = data.data.total;
                    }
                }
                else{
                    this.datas = data.data;
                }

            }).catch(err => {
                console.log(err)
            }).finally(() => this.$store.dispatch('settings/setLoading',false));
        },

        handleAdd() {
            this.id = null;
            this.modalVisible = true;
        },
        handleEdit(id) {
            this.id = id;
            this.modalVisible = true;
        },
        paginationPageClick(e,page) {
            this.pagination.page = page;
            this.fetchList()
        },
        handleDelete(id) {
            if(id){
                this.$swal({
                    title: this.$t('message.areYouSure'),
                    text: this.$t('message.wontReturn'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('message.YesDelete'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        this.actions.delete({id}).then(res => {
                            this.$swal({
                                icon: 'success',
                                title: this.$t('message.Deleted'),
                                text: this.$t('message.dataHasDeleted'),
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            });
                            this.fetchList();
                        })
                    } else if (result.dismiss === 'cancel') {
                        this.$swal({
                            title: this.$t('message.Cancelled'),
                            text: this.$t('message.yrDataSafe'),
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    }
                })
            }
        }
    },

}

<template>
  <section class="filters w-100">
    <b-card>
      <b-row align-h="start">
        <b-col cols="2">
          <!--          <label for="search">{{ $t('message.Search') }}</label>-->
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
                v-model="search"
                :placeholder="$t('message.Search')"
            />
          </b-input-group>
        </b-col>

        <slot name="customFilters" />

        <b-col
            v-if="createButton"
            cols="1"
        >
          <div class="d-flex justify-content-end" v-if="createButton">
            <b-button
                variant="primary"
                @click="$emit('handleAdd')"
            >
              {{ $t('message.Add') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
export default {
  name: 'CustomFilter',
  props: {
    createButton: {
      default: true,
      type: Boolean,
    },
    cancel:{
      default: false,
      type:Boolean
    },
    search: {
      default: '',
    }
  },
  data() {
    return {
      timeOutVar: null,
      firstTime: true,
    }
  },
  watch: {
    search: {
      immediate: true,
      handler(val) {
        if (!this.firstTime) {
          clearTimeout(this.timeOutVar)
          this.timeOutVar = setTimeout(() => {
            this.$emit('handleSearch', this.search)
          }, 800)
        } else {
          this.firstTime = false
        }
      },
    },
  },

}
</script>

<style scoped>

</style>
